import React, { useState } from 'react'
import politique from '../docs/politique.pdf'
import api from '../api/api'

const Footer = () => {
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [statut, setStatut] = useState()

    const handleNewletter = (e) => {
        e.preventDefault()

        api.get(`groupage/subscribe?email=${email}`)
        .then((res) => {
            setMessage("Inscription réussie")
            setStatut('success')
        }).catch((err) => {
            setMessage("Une erreur est survenue")
            setStatut('warning')
        })
    }

  return (
    <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s" style={{marginTop: "6rem"}}>
        <div className="container py-3">
            <div className="row d-flex justify-content-between align-items-center g-5">
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Adresses en Chine</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@leap-logistics.com</p>
                </div>
            
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Liens utils</h4>
                    <a className="btn btn-link" href="/#about">A propos</a>
                    <a className="btn btn-link" href="/#contact">Contact</a>
                    <a className="btn btn-link" href="/#services">Services</a>
                    <a className="btn btn-link" href={politique}>Avis de confidentialité</a>
                    <a className="btn btn-link" href="/">FAQ</a>
                </div>

                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">News letter</h4>
                    <form action="" onSubmit={(e) => handleNewletter(e)}>
                        <label htmlFor="email" className='form-label'>Email</label>
                        <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='form-control border-0' placeholder='johndoe@gmail.com' />
                        {
                            message && statut &&
                            <div className={`alert alert-${statut} fade show mt-2`} role="alert">
                                {message}
                            </div>
                        }
                        <button className="btn btn-primary mt-2" type="submit">S'inscrire</button>
                    </form>
                </div>

                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Adresse au Burkina</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Ouagadougou, Zone 1, non loin du marché Katr Yaar</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+226 53944545</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>info@leap-logistics.com</p>
                </div>
                
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row d-flex justify-content-between align-items-center gap-md-0 gap-3">
                    <div className="col-md-6 text-center text-md-start mb-md-0">
                        &copy; <a className="border-bottom" href="/">Leap Logistics</a>, All Right Reserved.
                    </div>
                    <div className="d-flex justify-content-md-end justify-content-center col-md-6">
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-whatsapp"></i></a>
                        <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/share/1EuXP5gfjK/"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-tiktok"></i></a>
                    </div>
                    {/* <div className="col-md-6 text-center text-md-end">
                        Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
                        <br />Distributed By <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer