export const checkStatusReceived = (status) => {
    return status.status === 'RECEIVED'
}

export const checkStatusInTransit = (status) => {
    return status.status === 'IN_TRANSIT'
}

export const checkStatusWithdrawn = (status) => {
    return status.status === 'WITHDRAWN'
}

export const checkStatusArrived = (status) => {
    return status.status === 'ARRIVED'
}