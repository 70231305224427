import React from 'react'
import logo from '../images/logo leap.png'

const Navigation = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0 pe-3">
        <a href="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
            <h2 className="mb-2 text-white">
                <img src={logo} height={'60px'} alt="" />
            </h2>
        </a>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
                <a href="/" className="nav-item nav-link">Accueil</a>
                <a href="/#about" className="nav-item nav-link">A propos</a>
                <a href="#services" className="nav-item nav-link">Services</a>
                {/* <a href="#prix" className="nav-item nav-link">Prix</a> */}
                <a href="#contact" className="nav-item nav-link">Contact</a>
            </div>
        </div>
    </nav>
  )
}

export default Navigation