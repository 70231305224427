import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import Caroussel from "./components/Caroussel";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
// import NextDeparture from "./components/NextDeparture";
// import Prices from "./components/Prices";
import Services from "./components/Services";
import Tracking from "./components/Tracking";
import Package from "./components/Package";

function App() {
  return (
    <>
      <Navigation />

      <Routes>
        <Route exact path="" element={
          <>
            <Caroussel />

            <About />

            <Services />

            {/* <Prices /> */}

            {/* <NextDeparture /> */}

            <Tracking />

            <Contact />
          </>
        } />

        <Route exact path="/tracking" element={<Package />} />
      </Routes>
      
      <Footer />
    </>
  );
}

export default App;
