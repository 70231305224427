import React from 'react'

import about from "../images/about.jpg"

const About = () => {
  return (
    <div className="container-fluid overflow-hidden py-5 px-lg-0" id='about'>
        <div className="container about py-5 px-lg-0">
            <div className="row g-5 mx-lg-0">
                <div className="col-lg-6 ps-lg-0 wow fadeInLeft" data-wow-delay="0.1s" style={{minHeight: "400px"}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={about} style={{objectFit: "cover"}} alt="" />
                    </div>
                </div>
                <div className="col-lg-6 about-text wow fadeInUp" data-wow-delay="0.3s">
                    <h6 className="text-secondary text-uppercase mb-3">A Propos de nous</h6>
                    <h1 className="mb-5">Leap Logistics</h1>
                    <p className="mb-5 text-justify">
                        Bienvenue chez <b>Leap Logistics</b>, votre partenaire de confiance pour des services logistiques efficaces et fiables, spécialement conçus pour les particuliers et les entreprises qui achètent des produits en Chine. <br /> Chez <b>Leap Logistic</b>, nous nous engageons à offrir une expérience de transport sans tracas, soutenue par une équipe d'experts passionnés par la réussite de chaque envoi.
                    </p>
                    <div className="row g-4 mb-5">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                            <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                            <h5>Couverture mondiale</h5>
                            <p className="m-0 text-justify">En tant que société de logistique,  nous nous spécialisons dans la simplification du processus complexe d’approvisionnement et de transport des marchandises depuis la Chine vers des destinations du monde entier.</p>
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                            <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                            <h5>Destinée à tout le monde</h5>
                            <p className="m-0 text-justify">Que vous soyez un consommateur individuel ou une entreprise cherchant à importer des produits, nous offrons des solutions logistiques complètes pour garantir que vos marchandises arrivent en toute sécurité, à temps et au meilleur prix possible.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About