import React, { useState } from 'react'
import api from '../api/api'

const Contact = () => {
    const [name, setName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [email, setEmail] = useState()
    const [request, setRequest] = useState()
    const [message, setMessage] = useState()
    const [statut, setStatut] = useState()

    const handleContact = (e) => {
        e.preventDefault()

        const data = {
            email, phoneNumber, name, request
        }

        api.post(`groupage/request`, data)
        .then((res) => {
            setMessage("Votre requête a bien été enregistrée")
            setStatut("success")
        }).catch((err) => {
            console.log(err)
            setMessage("Une erreur est survenue")
            setStatut("warning")
        })
    }

  return ( 
    <div className="container-xxl py-5" id='contact'>
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-secondary text-uppercase mb-3">Contactez nous</h6>
                    <h1 className="mb-5">Demandez votre devis gratuit !</h1>
                    <p className="mb-5">Besoin de services logistiques rapides, fiables et abordables ? Remplissez le formulaire ci-dessous et recevez un devis personnalisé. <br />Nous simplifions vos expéditions. Commencez dès aujourd’hui !</p>
                    {/* <div className="d-flex align-items-center">
                        <i className="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white"></i>
                        <div className="ps-4">
                            <h6>Call for any query!</h6>
                            <h3 className="text-primary m-0">+012 345 6789</h3>
                        </div>
                    </div> */}
                </div>
                <div className="col-lg-7">
                    <div className="bg-light text-center p-5 wow fadeIn" data-wow-delay="0.5s">
                        <form onSubmit={(e) => handleContact(e)}>
                            <div className="row g-3">
                                <div className="col-12 col-sm-12">
                                    {
                                        message && statut &&
                                        <div className={`alert alert-${statut} fade show mt-2`} role="alert">
                                            {message}
                                        </div>
                                    }
                                </div>
                                <div className="col-12 col-sm-12">
                                    <input type="text" className="form-control border-0" value={name} onChange={(e) => setName(e.target.value)} placeholder="Votre nom" style={{height:"55px"}}/>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input type="email" className="form-control border-0" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Votre Email" style={{height:"55px"}}/>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <input type="text" className="form-control border-0" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Votre numéro de téléphone" style={{height:"55px"}}/>
                                </div>
                                
                                <div className="col-12">
                                    <textarea className="form-control border-0" value={request} onChange={(e) => setRequest(e.target.value)} rows={5} style={{'resize' : "none"}} placeholder="Votre message"></textarea>
                                </div>
                                
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Envoyer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact