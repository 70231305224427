import React from 'react'

import service_1 from "../images/service-1.jpg"
import service_2 from "../images/service-2.jpg"
import service_3 from "../images/service-3.jpg"
import service_4 from "../images/service-4.jpg"
import service_5 from "../images/service-5.jpg"
import service_6 from "../images/service-6.jpg"
// import service_7 from "../images/service-7.jpg"

const Services = () => {
  return (
    <div className="container-xxl py-5" id='services'>
        <div className="container py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h3 className="text-secondary text-uppercase mb-5">Nos services</h3>
                {/* <h5 className="mb-5">Chez Leap Logistic, nous proposons des services logistiques complets pour répondre à tous vos besoins liés à l’achat de produits en Chine. </h5> */}
            </div>
            <div className="row g-4">
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_1} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Assistance à l’achat de produits</h4>
                        <p style={{textAlign:'justify'}}>Laissez-nous vous aider à trouver les bons produits auprès de fournisseurs fiables en Chine. Notre équipe expérimentée vous accompagnera pour identifier des fabricants de confiance, négocier les prix et garantir la qualité des produits correspondant à vos besoins.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_2} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Affrètement</h4>
                        <p style={{textAlign:'justify'}}>Nous nous chargeons de toute la logistique pour transporter vos produits depuis la Chine vers votre destination. Que vous optiez pour le fret aérien, ou maritime nous assurons les options d'expédition les plus rapides et les plus rentables pour que vos marchandises arrivent à bon port.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_3} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Dédouanement et documentation</h4>
                        <p style={{textAlign:'justify'}}>Naviguer dans les formalités douanières internationales peut être complexe. Nous nous occupons de toute la documentation nécessaire et veillons à un dédouanement sans heurts, minimisant les retards et garantissant l'arrivée de votre expédition à temps.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_4} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Stockage et consolidation</h4>
                        <p style={{textAlign:'justify'}}>Si vous achetez auprès de plusieurs fournisseurs, nous proposons des services de stockage et de consolidation pour regrouper vos envois. Cela permet de réduire les coûts d'expédition et d'améliorer l'efficacité, avec tous vos produits stockés en toute sécurité jusqu'à leur expédition.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_5} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Livraison porte-à-porte</h4>
                        <p style={{textAlign:'justify'}}>Notre service de livraison porte-à-porte garantit que vos produits sont livrés directement à l'adresse de votre choix, sans tracas ni effort supplémentaire de votre part. Nous travaillons avec des partenaires locaux fiables pour assurer une livraison de dernière étape efficace.</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_6} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Suivi et mises à jour</h4>
                        <p style={{textAlign:'justify'}}>Restez informé grâce à notre système de suivi en temps réel, vous permettant de suivre vos expéditions depuis le moment où elles quittent la Chine jusqu'à leur arrivée à votre porte. Nous vous fournissons des mises à jour régulières sur l’avancement de votre livraison.</p>
                    </div>
                </div>
                {/* <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="service-item p-4">
                        <div className="overflow-hidden mb-4">
                            <img className="img-fluid" src={service_7} alt=""/>
                        </div>
                        <h4 className="mb-3 text-center">Emballage et assurance qualité</h4>
                        <p className='text-justify'>Nous veillons à ce que vos produits soient correctement emballés pour éviter tout dommage pendant le transport. De plus, notre équipe effectue des contrôles qualité pour s'assurer que les produits respectent vos spécifications avant leur expédition.</p>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Services