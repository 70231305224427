import React, { useEffect, useState } from 'react'

import feature from "../images/feature.jpg"
import { useSearchParams } from 'react-router-dom'
import api from '../api/api'
import { checkStatusArrived, checkStatusInTransit, checkStatusReceived, checkStatusWithdrawn } from '../utils/checkStatus'
import { retrieveStatusDate } from '../utils/retrieveDate'

const Package = () => {
    const [packageInfos, setPackageInfos] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const [error, setError] = useState(false)

    useEffect(() => {
        if (searchParams.get('numero')){
            api.get(`groupage/parcel?id=${searchParams.get('numero')}`)
            .then((res) => {
                setPackageInfos(res.data)
            }).catch((err) => {
                console.log(err)
                setError(true)
            })
        }
    }, [searchParams])

  return (
    <>
        <div className="container-fluid overflow-hidden py-5 px-lg-0" id='tracking'>
            {(packageInfos && !error) &&
                <>
                    <div className="container feature py-5 px-lg-0">
                        <div className="row g-5 mx-lg-0">
                            <div className="col-lg-6 feature-text wow fadeInUp" data-wow-delay="0.1s">
                                <h6 className="text-secondary text-uppercase mb-3">Informations du paquet</h6>
                                <h1 className="mb-5">{packageInfos.label}</h1>
                                <div className="d-flex mb-5 wow fadeInUp" data-wow-delay="0.3s">
                                    <i className="fa fa-money-bill-wave-alt text-primary fa-3x flex-shrink-0"></i>
                                    <div className="ms-4">
                                        <h5>Prix</h5>
                                        <p className="mb-0">{packageInfos.price}</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
                                    <i className="fa fa-calendar text-primary fa-3x flex-shrink-0"></i>
                                    <div className="ms-4">
                                        <h5>Date {(packageInfos.status === 'RECEIVED' || packageInfos.status === 'IN_TRANSIT') && "approximative"}  d'arrivée</h5>
                                        <p className="mb-0">{packageInfos.expectedArrivalDay.slice(0, 10)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pe-lg-0 wow fadeInRight" data-wow-delay="0.1s" >
                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="w-100" height={'350px'} src={feature} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100" height={'350px'} src={feature} alt="" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100" height={'350px'} src={feature} alt="" />
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div className="container feature py-5 px-lg-0 bg-primary" >
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="text-secondary text-uppercase">Statut</h6>
                            <h1 className="mb-5">Position du paquet</h1>
                        </div>
                        <div className="progress-track">
                            <ul id="progressbar">
                                {packageInfos.statusList.some(checkStatusReceived) ? <li className="step0 active" id="step1">RECEIVED <br />{retrieveStatusDate(packageInfos.statusList, 'RECEIVED').slice(0, 10)}</li> : <li className="step0" id="step1">RECEIVED</li>}
                                {packageInfos.statusList.some(checkStatusInTransit) ? <li className="step0 active text-center" id="step2">IN_TRANSIT <br />{retrieveStatusDate(packageInfos.statusList, 'IN_TRANSIT').slice(0, 10)}</li> : <li className="step0 text-center" id="step2">IN_TRANSIT</li>}
                                {packageInfos.statusList.some(checkStatusArrived) ? <li className="step0 active text-end" id="step3">ARRIVED <br />{retrieveStatusDate(packageInfos.statusList, 'ARRIVED').slice(0, 10)}</li> : <li className="step0 text-end" id="step3">ARRIVED</li>}
                                {packageInfos.statusList.some(checkStatusWithdrawn) ? <li className="step0 active text-end" id="step4">WITHDRAWN <br />{retrieveStatusDate(packageInfos.statusList, 'WITHDRAWN').slice(0, 10)}</li> : <li className="step0 text-end" id="step4">WITHDRAWN</li>}
                            </ul>
                        </div>
                    </div>
                </>
            }
                
            {
                !packageInfos &&
                <div className="container feature py-5 px-lg-0">
                    <div className="row g-5 mx-lg-0">
                        <div className="col-lg-12 feature-text wow text-center fadeInUp" data-wow-delay="0.1s">
                            <h6 className="text-secondary text-uppercase mb-3">Informations du paquet</h6>
                            {error ? <h1 className="mb-5">Vous avez saisi un identifiant de paquet incorrect</h1> : <h1 className="mb-5">Veuillez renseigner l'identifiant du paquet</h1> }
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
  )
}

export default Package